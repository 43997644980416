import React from 'react'
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//material icons
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SendIcon from '@mui/icons-material/Send';

//gi icons
import { GiGuitarHead, GiGrandPiano, GiNightSleep, GiLargeDress } from "react-icons/gi";

// components
import Page from '../components/Page'

import { parse } from 'node-html-parser'

import TestHTML from '../components/TestHTML'
//import MIDISounds from 'midi-sounds-react'
import MIDISounds from '../components/music/Midi'

import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'

import { GuitarStrings, GuitarChords, ChordsInKey, ChordsProgression, ABCNotations, ChordsInKeyNames } from '../components/music/Guitar'

import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { Notation, Midi, Editor } from 'react-abc';

import abcjs from 'abcjs'


const bassDrum = 2
const snare = 17
const hiHat = 56
const cymbal = 70

const palmMute = 304
const bassGuitar = 384
const overdrive = 338

const Instrument = {
  Piano: 0,
  Organ: 182,
  Cello: 469,
  Contrabass: 487,
}


export default class Music extends React.PureComponent {
  constructor(props) {
		super(props);
		this.state = {
      keyUsed: 'C',
      urlToLoad: 'http://www.google.com',
      urlContent: '',
      minimumChildren: 0,
      allowedTags: ['div', 'p', 'ul', 'ol', 'li', 'a', 'table', 'tr', 'html', 'body', 'nav', 'section', 'form', 'input', 'label', 'img', 'span'],
      selectedTags: ['div', 'p', 'ul', 'ol', 'li', 'a', 'table', 'tr', 'html', 'body', 'nav', 'section', 'form', 'input', 'label', 'img', 'span'],
      playType: 0,
      isPlaying: false,
      canPlay: false,
      linkToShare: '',
      snackOpen: false,
      snackMessage: '',
      record: 0,
      playProgress: 0,
      totalBeatsCount: 0,
      bpm: 80,
      abcNotes: 'X:1\nM: 4/4\nCDEF GABc|',
      isScreenRecording: false,
      webRTCAvailable: false,
      showScores: false,
      emailNotify: '',
		};
    this.notationRef = React.createRef();
    this.editorTextRef = React.createRef();
	}

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    console.log(params)
    this.setState({ urlToLoad: params.url ? params.url : this.state.urlToLoad})
    this.setState({ keyUsed: params.keyUsed ? params.keyUsed : this.state.keyUsed})
    this.setState({ minimumChildren: params.minimumChildren ? parseInt(params.minimumChildren) : this.state.minimumChildren})
    this.setState({ playType: params.playType ? parseInt(params.playType) : this.state.playType})
    this.setState({ bpm: params.bpm ? parseInt(params.bpm) : this.state.bpm})
    this.parsedABCNotes = 'X:1\nM: 4/4\nL: 1/4\n'
    this.renderABCNotation()

    let isWebRTCSupported = navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      window.RTCPeerConnection
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
        isWebRTCSupported = false
    }
    this.setState({webRTCAvailable: isWebRTCSupported})
  }

  use_instrument = 3

  useProPos = 0

  snaresCount = 0

  progressionType = 0

  currentLevel = 0

  playWithInstrument = {
    speed: 1,
    primary: 0,
    secondary: 0,
    tertiary: 0,
  }

  ac = null
  dest = null
  mediaRecorder = null
  chuncks = []

  parsedABCNotes = ''

  secondaryChannelData = []



  addBeats(data) {
    const selectedKey = data.selectedKey
    const useProgression = data.useProgression
    let progressionPosition = data.progressionPosition



    let usePT = this.progressionType % ChordsProgression[selectedKey].length

    let useProgressionPosition = progressionPosition % ChordsProgression[selectedKey][usePT].length

    if (useProgressionPosition === ChordsProgression[selectedKey][usePT].length-1) this.progressionType++

    if (usePT === 0) useProgressionPosition = 0

    //if (progressionPosition >= ChordsProgression[selectedKey][useProgression].length) progressionPosition = 0
    const useChords = ChordsInKey[selectedKey]
    let currentChordPosition = ChordsProgression[selectedKey][usePT][useProgressionPosition]
    let currentChord = useChords[currentChordPosition]
    let currentChordName = ChordsInKeyNames[selectedKey][currentChordPosition]
    return {
      chord: currentChord,
      chordName: currentChordName,
    }
  }

  parseElementGuitar(el, populated, currentPosition) {
    let useAllowedTags = [null].concat(this.state.selectedTags)
    if (useAllowedTags.indexOf(el.rawTagName)<0) return

    if (this.state.minimumChildren && this.currentLevel > 3) {
      if (!el.childNodes) return
      if (el.childNodes.length < this.state.minimumChildren) return
    }

    if (el.childNodes && el.childNodes.length) {
      let chordObject = this.addBeats({
        selectedKey: this.state.keyUsed,
        useProgression: this.progressionType,
        progressionPosition: currentPosition
      })
      let chord = chordObject.chord
      currentPosition++
      this.useProPos++
      let maxDuration = Math.min(16, el.childNodes.length)
      if (this.state.minimumChildren) maxDuration /= this.state.minimumChildren

      let drumBeat = []
      if (el.rawTagName === 'div') drumBeat = [bassDrum, hiHat]
      if (el.rawTagName === 'li') drumBeat = [snare]
      if (el.rawTagName === 'input') drumBeat = [snare]
      if (el.rawTagName === 'label') drumBeat = [snare]
      if (el.rawTagName === 'ul') drumBeat = [hiHat]
      if (el.rawTagName === 'a') drumBeat = [bassDrum]

      let instrumentBeat = [
        [overdrive, chord, maxDuration/16, 1]
      ]
      if (el.rawTagName === 'input') instrumentBeat = [[]]
      if (el.rawTagName === 'label') instrumentBeat = [[]]

      let oneBeat = [
        drumBeat, instrumentBeat
      ]
      populated.push(oneBeat)
      if (populated.length % 16 ===0) this.parsedABCNotes += '|\n'
      this.parsedABCNotes += '"' + chordObject.chordName + '"'
      //this.parsedABCNotes += 'z' + maxDuration + '/4'
      let str = ''
      for (let c=0; c<chord.length; c++) {
        let tone = chord[c]
        let note = ABCNotations['note_' + tone]
        str += note + maxDuration + '/4'
      }
      this.parsedABCNotes += '[' + str + ']'
      this.snaresCount = 0

      for (let i = 1; i < maxDuration; i++) {
        let emptyBeat = [[], []]
        populated.push(emptyBeat)
        if (populated.length % 16 ===0) this.parsedABCNotes += '|\n'
        //this.parsedABCNotes += 'z1/16'
      }
      //this.parsedABCNotes += 'z' + (maxDuration-1) + '/16'
      if (populated.length % 16 ===0) this.parsedABCNotes += '|\n'

      el.childNodes.forEach((item, i) => {
        this.currentLevel++
        let parsed = this.parseElementGuitar(item, populated, this.useProPos)
        this.currentLevel--
      })
    } else {
      let snareBeat = [[snare], []]
      if (this.snaresCount < 20000000000) {
        populated.push(snareBeat)
        if (populated.length % 16 ===0) this.parsedABCNotes += '|\n'
        this.parsedABCNotes += 'z1/4'
        this.snaresCount++
      }
    }
  }

  parseElementMulti(el, populated, currentPosition) {
    let useAllowedTags = [null].concat(this.state.selectedTags)
    if (useAllowedTags.indexOf(el.rawTagName)<0) return

    if (this.state.minimumChildren && this.currentLevel > 3) {
      if (!el.childNodes) return
      if (el.childNodes.length < this.state.minimumChildren) return
    }

    if (el.childNodes && el.childNodes.length) {
      let chordObject = this.addBeats({
        selectedKey: this.state.keyUsed,
        useProgression: this.progressionType,
        progressionPosition: currentPosition
      })
      let chord = chordObject.chord

      currentPosition++
      this.useProPos++
      let maxDuration = Math.min(16, el.childNodes.length)
      if (this.state.minimumChildren) maxDuration /= this.state.minimumChildren

      let drumBeat = []
      if (el.rawTagName === 'div') drumBeat = [bassDrum, hiHat]
      if (el.rawTagName === 'li') drumBeat = [snare]
      if (el.rawTagName === 'input') drumBeat = [snare]
      if (el.rawTagName === 'label') drumBeat = [snare]
      if (el.rawTagName === 'ul') drumBeat = [hiHat]
      if (el.rawTagName === 'ol') drumBeat = [hiHat]
      if (el.rawTagName === 'a') drumBeat = [bassDrum]
      if (el.rawTagName === 'img') drumBeat = [bassDrum, hiHat]
      if (el.rawTagName === 'span') drumBeat = [snare]
      if (this.playWithInstrument.speed>2) drumBeat = []

      let instrumentBeat = [
        //[this.playWithInstrument.primary, chord, (maxDuration/16), 1]
        //[this.playWithInstrument.primary, chord, (maxDuration*4/16), 1]
        [this.playWithInstrument.primary, chord, (maxDuration*2*this.playWithInstrument.speed/8), 1]
        //[Instrument.Cello, chord, (16/16), 1]
      ]
      if (el.rawTagName === 'input') instrumentBeat = [[]]
      if (el.rawTagName === 'label') instrumentBeat = [[]]

      let oneBeat = [
        drumBeat, instrumentBeat
      ]
      //populated.push(oneBeat)
      this.parsedABCNotes += '"' + chordObject.chordName + '"'
      //this.parsedABCNotes += 'z1/8'

      if (el.rawTagName === 'img' || el.rawTagName === 'span') {
        if (drumBeat != []) {
          /*
          populated.push([drumBeat, []])
          populated.push([drumBeat, []])
          populated.push([drumBeat, []])
          this.parsedABCNotes += 'z3/16'
          */
        }
      }

      this.snaresCount = 0

      for (let i = 0; i < maxDuration*4; i++) {
        if (i===0) this.secondaryChannelData.push(oneBeat)
        let tone = chord[i%chord.length]
        let note = ABCNotations['note_' + tone]
        this.parsedABCNotes += note + (this.playWithInstrument.speed) + '/4'
        //if (this.playWithInstrument.speed === 1) this.parsedABCNotes += '1/2'
        //if (this.playWithInstrument.speed === 2) this.parsedABCNotes += '1/4'

        let useChord = [tone]
        if (i===0) useChord = [tone].concat(chord)
        let useBeat = [
          //[this.playWithInstrument.secondary, [tone], (this.playWithInstrument.speed)/16, 1]
          //[Instrument.Piano, useChord, (this.playWithInstrument.speed)/8, 1]
          [this.playWithInstrument.secondary, useChord, (this.playWithInstrument.speed)/(8), 1]
        ]
        let useDrumBeat = []
        if (i%4 === 0) useDrumBeat = drumBeat
        let multiBeat = [useDrumBeat, useBeat]
        populated.push(multiBeat)
        this.secondaryChannelData.push([[], []])
        if (populated.length % 16 ===0) this.parsedABCNotes += '|\n'

        for (let j=0; j<this.playWithInstrument.speed-1; j++) {
          populated.push([[], []])
          this.secondaryChannelData.push([[], []])
          if (populated.length % 16 ===0) this.parsedABCNotes += '|\n'
          //this.parsedABCNotes += 'z1/16'
        }
        //i += this.playWithInstrument.speed - 1
      }

      el.childNodes.forEach((item, i) => {
        this.currentLevel++
        let parsed = this.parseElementMulti(item, populated, this.useProPos)
        this.currentLevel--
      })
    } else {
      let snareBeat = [[snare], []]
      if (this.playWithInstrument.speed > 2) {
      } else {
        /*
        populated.push(snareBeat)
        populated.push(snareBeat)
        populated.push(snareBeat)
        populated.push(snareBeat)
        populated.push(snareBeat)
        populated.push(snareBeat)
        populated.push(snareBeat)
        populated.push(snareBeat)
        this.parsedABCNotes += 'z8/16'
        */
      }
      this.snaresCount++
    }
  }


  parseTest() {
    const root = parse(TestHTML)
    console.log(root)

    //let rb = []
    //this.parseElementGuitar(root, rb, 0)
    //console.log(rb)

    //this.midiSounds.startPlayLoop(rb, 80, 1/16)

    return


    let herojUlice = [
      [[bassDrum, hiHat], [[overdrive, GuitarChords.Am, 2/16, 1]]],
      [[], []],
      [[], [[overdrive, GuitarChords.Am, 1/16, 2]]],
      [[], [[overdrive, GuitarChords.Am, 2/16, 1]]],
      [[snare], []],
      [[bassDrum], [[overdrive, GuitarChords.Am, 1/16, 2]]],
      [[bassDrum, hiHat], [[overdrive, GuitarChords.Am, 1/16, 1]]],
      [[bassDrum], [[overdrive, GuitarChords.Am, 1/16, 2]]],

      [[bassDrum, hiHat], [[overdrive, GuitarChords.F, 2/16, 1]]],
      [[], []],
      [[], [[overdrive, GuitarChords.F, 1/16, 2]]],
      [[], [[overdrive, GuitarChords.F, 2/16, 1]]],
      [[snare], []],
      [[bassDrum], [[overdrive, GuitarChords.F, 1/16, 2]]],
      [[bassDrum, hiHat], [[overdrive, GuitarChords.F, 1/16, 1]]],
      [[bassDrum], [[overdrive, GuitarChords.F, 1/16, 2]]],

      [[bassDrum, hiHat], [[overdrive, GuitarChords.G, 2/16, 1]]],
      [[], []],
      [[], [[overdrive, GuitarChords.G, 1/16, 2]]],
      [[], [[overdrive, GuitarChords.G, 2/16, 1]]],
      [[snare], []],
      [[bassDrum], [[overdrive, GuitarChords.G, 1/16, 2]]],
      [[bassDrum, hiHat], [[overdrive, GuitarChords.G, 1/16, 1]]],
      [[bassDrum], [[overdrive, GuitarChords.G, 1/16, 2]]],

      [[bassDrum, hiHat], [[overdrive, GuitarChords.C, 2/16, 1]]],
      [[snare], []],
      [[snare], [[overdrive, GuitarChords.C, 1/16, 2]]],
      [[snare], [[overdrive, GuitarChords.C, 2/16, 1]]],
      [[snare], []],
      [[bassDrum], [[overdrive, GuitarChords.C, 1/16, 2]]],
      [[bassDrum, hiHat], [[overdrive, GuitarChords.C, 1/16, 1]]],
      [[bassDrum], [[overdrive, GuitarChords.C, 1/16, 2]]],
    ]

    this.midiSounds.startPlayLoop(herojUlice, 60, 2/16);
  }

  stopPlaying() {
    this.midiSoundsGuitar.stopPlayLoop()
    this.midiSoundsMulti.stopPlayLoop()
    this.midiSoundsMultiSecondary.stopPlayLoop()
    if (this.abcTimingCallbacks) this.abcTimingCallbacks.stop()
    if (this.recorder && this.state.isScreenRecording) {
      setTimeout(() => {
        if (this.recorder.state !== 'inactive' && this.recorder.state !== 'paused') this.recorder.stop()
      }, 4500)
    }
    this.recorderRunning = true
  }

  handleOneBeat(currentBeat) {
    this.props.parentComponent.setState({playProgress: currentBeat.beatIndex / this.props.parentComponent.state.totalBeatsCount * 100 })
    if (this.props.parentComponent.abcTimingCallbacks) {
      //console.log('Syncing')
      this.props.parentComponent.abcTimingCallbacks.setProgress(currentBeat.beatIndex / this.props.parentComponent.state.totalBeatsCount)
    }
    if (currentBeat.beatIndex >= this.props.parentComponent.state.totalBeatsCount-1 && this.props.parentComponent.state.record === 0) {
      this.props.parentComponent.stopPlaying()
      this.props.parentComponent.setState({isPlaying: false, playProgress: 100})
      //this.renderABCNotation()
      //this.abcTimingCallbacks.stop()
    }
    if (currentBeat.beatIndex >= this.props.parentComponent.state.totalBeatsCount-1 && this.props.parentComponent.state.record === 1) {
      this.props.parentComponent.stopPlaying()
      this.props.parentComponent.setState({snackOpen: true, snackMessage: 'File prepared for download!', isPlaying: false, playProgress: 100})
      //this.renderABCNotation()
      //this.abcTimingCallbacks.stop()
    }
  }

  abcVisualObject = null
  abcTimingCallbacks = null

  renderABCNotation() {
    if (!this.state.showScores) {
      this.abcVisualObject = null
      this.abcTimingCallbacks = null
      let diver = document.querySelector('#mojdiv')
      while (diver.firstChild) {
          diver.removeChild(diver.lastChild);
        }
      return
    }
    let op = document.querySelector('#mojpaper')
    var visualOptions = {
      viewportHorizontal: true,
      scrollHorizontal: true,
      staffwidth: op.getBoundingClientRect().width-60,
      wrap: {
        minSpacing: 1.8,
        maxSpacing: 2.7,
        preferredMeasuresPerLine: 4,
      }
      //responsive: 'resize',
    };
    this.abcVisualObject = abcjs.renderAbc("mojdiv", this.parsedABCNotes + "|", visualOptions);
    console.log(this.abcVisualObject)
    let o = document.querySelector('.abcjs-inner svg')
    console.log(o.height)
    console.log('Baseval: ' + o.height.baseVal.value)
    let oneLineHeight = o.height.baseVal.value; // / this.abcVisualObject[0].lines.length
    console.log('Calculated height ' + oneLineHeight)
    console.log(this.abcVisualObject[0].lines.length)
    o.style.position = 'relative'
    let use_left_pos = o.style.left
    console.log(o)
    let refreshCalled = false
    let staffHeightSum = 0
    let start_y = o.getBoundingClientRect().top + 1
    let max_y = 0
    this.abcTimingCallbacks = new abcjs.TimingCallbacks(this.abcVisualObject[0], {
      qpm: this.state.bpm,
      lineEndCallback: (info, event, details) => {
      },
      eventCallback: (evt) => {
        if (evt) {
          if (!refreshCalled && evt.milliseconds >= this.abcVisualObject[0].noteTimings[this.abcVisualObject[0].noteTimings.length-1].milliseconds-3000) {
            refreshCalled = true
            setTimeout(() => {
              this.renderABCNotation()
            }, 4000)
          }

          let value_y = this.abcVisualObject[0].lines[evt.line].staffGroup.staffs[0].absoluteY - 150; // + 18 * evt.line + 15 * evt.line
          if (value_y > max_y && value_y > 0) {
            max_y = value_y
            op.scrollTo({
              top: max_y,
              left: 0,
              behavior: 'smooth',
            })
          }
          for (var i = 0; i < evt.elements.length; i++ ) {
      			var note = evt.elements[i];
      			for (var j = 0; j < note.length; j++) {
              note[j].style.fill = '#3366ff'
      			}
      		}
        }
      },
      beatCallback: (evt) => {
        //console.log('Beat')
        //console.log(evt)
      }
    });
  }

  recorderRunning = false
  recorder = null
  recStream = null
  recChuncks = []

  recStuffStart() {
    if (!this.recorder) this.recorder = new MediaRecorder(this.recStream);
    if (this.recorder) this.setState({isScreenRecording: true})

    this.recChunks = [];
    this.recorder.ondataavailable = (e) => {
      this.recChunks.push(e.data);
      this.setState({isScreenRecording: true})
    }
    this.recorder.onstop = e => {
        console.log(e)
        const blob = new Blob(this.recChunks, { type: 'video/webm;codecs=vp8,opus' /*this.recChunks[0].type*/ });
        console.log(blob);
        //this.recStream.getVideoTracks()[0].stop();

        let filename = "html2music_screen_recording"
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        this.setState({isScreenRecording: false})
      };
    if (this.recorder.state === 'paused') {
      this.recorder.resume()
    } else {
      this.recorder.start();
    }
  }

  screenRec() {
    async function startRecording(pr) {
      console.log('aaa')
      if (!pr.recStream) {
        pr.recStream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            cursor: "always"
          },
          audio: {
            echoCancellation: false,
            noiseSuppression: false,
            sampleRate: 44100
          }
        });
        pr.recStream.oninactive = () => {
          console.log('User stopped sharing')
          pr.setState({isScreenRecording: false})
          pr.recorderRunning = false
          pr.recorder = null
          pr.recStream = null
        }
      } else {
        //pr.recStream.getVideoTracks()[0].start()
        console.log(pr.recStream)
      }
      pr.recStuffStart()
      pr.recorderRunning = false
    }

    startRecording(this)
  }


  render() {
    return (
      <Page title="HTML 2 Music">
        <Snackbar
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={() => {this.setState({snackOpen: false})}}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert onClose={() => {this.setState({snackOpen: false})}} severity="info" sx={{ width: '100%' }}>
            {this.state.snackMessage}
          </MuiAlert>
        </Snackbar>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={3}>
                <Typography variant="h4" gutterBottom>
                  HTML to Music
                </Typography>
                <Paper square elevation={3} sx={{ mb: 1 }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Description & Instructions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body1" gutterBottom>
                        Did you ever wonder how would user inteface sound if it would be converted to melody? No? OK, neither was I until early January 2022. I was playing my
                        guitar, thinking about patterns, scales, harmonies and then it struck me! Decent UI should be structured, harmonic and follow certain UX patterns, right?
                        Well, then it should sound melodic if converted to music, right? That sounds about right to me.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Fill in the url (with http:// or https://) of the web page you would like to listen to, press the <CloudDownloadOutlinedIcon sx={{ml: 1, mr: 1}} /> button to download its HTML.
                        Press <PlayArrowIcon sx={{ml: 0, mr: 0}} /> button to play the melody and <StopIcon sx={{ml: 0, mr: 0}} /> to stop playing.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        If the app for whatever reason can't download the HTML, open the desired URL in a new tab, open developer console, in <i>Elements</i> tab copy the <strong>body</strong> element
                        and paste it here into the HTML box. Do the same for single page applications built in react (like this one).
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Change the key, structure depth level and play type to generate different harmonies. Control the structure selecting allowed HTML tags to generate
                        completely different melody!
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        You need to use this app on a desktop/laptop to download generated music. Downloaded files are in <strong>ogg</strong> format, convert them to mp3 or any other format
                        using 3rd party tools like <a href="https://converter.app/ogg-to-mp3/" target="_blank">converter.app</a>.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        If the browser is WebRTC compliant, screen record button <FiberManualRecordIcon sx={{ml: 1, mr: 1}} /> will be shown. Make sure you will share <strong>TAB</strong> with audio sharing option checked.
                        This will generate <i>WebM</i> video and audio file that will be downloaded to your mobile device / computer.
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Enjoy the melodies and ping me on IG or Twitter <strong>@kstevica</strong>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Paper>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'none' }}>
                    <MIDISounds ref={(musicRef) => (this.midiSoundsGuitar = musicRef)} appElementName="roothtmlmusic" instruments={[3, 304, 384, 338]} drums={[bassDrum,snare,hiHat,cymbal]} handleBeat={this.handleOneBeat} parentComponent={this} />
                    <MIDISounds ref={(musicRef) => (this.midiSoundsMulti = musicRef)} appElementName="roothtmlmusic" instruments={[Instrument.Piano, Instrument.Organ, Instrument.Cello, Instrument.Contrabass]} drums={[bassDrum,snare,hiHat,cymbal]} handleBeat={this.handleOneBeat} parentComponent={this} />
                    <MIDISounds ref={(musicRef) => (this.midiSoundsMultiSecondary = musicRef)} appElementName="roothtmlmusic" instruments={[Instrument.Piano, Instrument.Organ, Instrument.Cello, Instrument.Contrabass]} drums={[bassDrum,snare,hiHat,cymbal]} handleBeat={() => {}} parentComponent={this} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper square elevation={3} sx={{ p: 2, mb: 3 }}>
                  <Typography variant="body1">
                    I am working on a music composer that will generate music for your poems and any other texts.
                    Enter your email to be notified when it's ready to test it!
                  </Typography>
                  <FormControl>
                    <TextField
                      disabled={this.state.isPlaying}
                      sx={{ mr: 1, mt: 1 }}
                      id="outlined-basic"
                      variant="outlined"
                      value={this.state.emailNotify}
                      label="Email"
                      onInput={(e) => {
                        this.setState({ emailNotify: e.target.value })
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{mb: 3,  mr: 1, mt: 0.5}}>
                    <IconButton
                      size="large"
                      disabled={this.state.isPlaying}
                      component="span"
                      variant="contained"
                      color="secondary"
                      component={RouterLink}
                      to="#"
                      onClick={() => {
                        let url = 'https://html2music.com/saveemail.php?email=' + this.state.emailNotify
                        console.log(url)
                        fetch(url)
                          .then((response) => {
                            return response.text()
                          })
                          .then((content) => {
                            this.setState({snackOpen: true, snackMessage: 'Thank you, email is saved!'})
                          })
                      }}
                    >
                      <SendIcon fontSize="large" />
                    </IconButton>
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper square elevation={3} sx={{ p: 2, mb: 3 }}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={this.state.isPlaying}
                        sx={{ mr: 1, width: '90%', maxWidth: '99%', minWidth: '98%' }}
                        id="outlined-basic"
                        variant="outlined"
                        value={this.state.urlToLoad}
                        label="URL"
                        onInput={(e) => {
                          this.setState({ urlToLoad: e.target.value })
                        }}
                      />
                    </FormControl>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper square elevation={3} sx={{ p: 2, mb: 3 }}>
                  <FormControl sx={{mb: 3, mt: 0.5,  mr: 1}}>
                    <IconButton
                      size="large"
                      disabled={this.state.isPlaying}
                      component="span"
                      variant="contained"
                      color="secondary"
                      component={RouterLink}
                      to="#"
                      onClick={() => {
                        this.setState({canPlay: false})
                        //let url = "http://localhost:8000/load.php?url=" + this.state.urlToLoad
                        let url = '/load.php?url=' + this.state.urlToLoad
                        console.log(url)
                        fetch(url)
                          .then((response) => {
                            return response.text()
                          })
                          .then((content) => {
                            this.setState({urlContent: content})
                            this.setState({canPlay: true})
                            this.setState({snackOpen: true, snackMessage: 'HTML Loaded!'})
                          })
                      }}
                    >
                      <CloudDownloadOutlinedIcon fontSize="large" />
                    </IconButton>
                  </FormControl>
                  <FormControl sx={{mb: 3, mr: 1}}>
                    <IconButton
                      disabled={this.state.isPlaying || !this.state.canPlay}
                      component="span"
                      sx={{ mt: 1 }}
                      variant="outlined"
                      color="secondary"
                      component={RouterLink}
                      to="#"
                      onClick={() => {
                        this.parsedABCNotes = 'X:1\nM: 4/4\nL: 1/4\n'
                        this.secondaryChannelData = []

                        this.useProPos = 0
                        this.setState({snackOpen: true, snackMessage: 'Started playing!', isPlaying: true})
                        let link = 'https://html2music.com?'
                        link += '&url=' + this.state.urlToLoad
                        link += '&playType=' + this.state.playType
                        link += '&minimumChildren=' + this.state.minimumChildren
                        link += '&keyUsed=' + this.state.keyUsed
                        link += '&bpm=' + this.state.bpm
                        this.setState({linkToShare: link})
                        if (this.state.playType === 0) {
                          let root = parse(this.state.urlContent)
                          let rb = []
                          this.playWithInstrument.speed = 1
                          this.playWithInstrument.primary = Instrument.Organ
                          this.playWithInstrument.secondary = Instrument.Piano
                          this.parseElementGuitar(root, rb, 0)
                          this.renderABCNotation()
                          this.setState({totalBeatsCount: rb.length, playProgress: 0});
                          this.midiSoundsGuitar.startPlayLoop(rb, this.state.bpm, 1/16, 0, this.state.record===1, '_hard_rock')
                          this.setState({isPlaying: true})
                          setTimeout(() => {
                            if (this.abcTimingCallbacks) this.abcTimingCallbacks.start()
                          }, 350)
                        }
                        if (this.state.playType === 1) {
                          let root = parse(this.state.urlContent)
                          let rb = []
                          this.playWithInstrument.speed = 1
                          this.playWithInstrument.primary = Instrument.Organ
                          this.playWithInstrument.secondary = Instrument.Piano
                          this.parseElementMulti(root, rb, 0)
                          this.renderABCNotation()
                          this.setState({totalBeatsCount: rb.length, playProgress: 0});
                          this.midiSoundsMultiSecondary.setInstrumentVolume(this.playWithInstrument.primary, 0.35)
                          this.midiSoundsMulti.startPlayLoop(rb, this.state.bpm, 1/16, 0, this.state.record===1, '_piano')
                          this.midiSoundsMultiSecondary.startPlayLoop(this.secondaryChannelData, this.state.bpm, 1/16, 0, false, '_piano_secondary')
                          this.setState({isPlaying: true})
                          setTimeout(() => {
                            if (this.abcTimingCallbacks) this.abcTimingCallbacks.start()
                          }, 350)
                        }
                        if (this.state.playType === 2) {
                          let root = parse(this.state.urlContent)
                          let rb = []
                          this.playWithInstrument.speed = 2
                          this.playWithInstrument.primary = Instrument.Piano
                          this.playWithInstrument.secondary = Instrument.Contrabass
                          this.parseElementMulti(root, rb, 0)
                          this.renderABCNotation()
                          this.setState({totalBeatsCount: rb.length, playProgress: 0});
                          this.midiSoundsMultiSecondary.setInstrumentVolume(this.playWithInstrument.primary, 0.35)
                          this.midiSoundsMulti.startPlayLoop(rb, this.state.bpm, 1/16, 0, this.state.record===1, '_ballroom')
                          this.midiSoundsMultiSecondary.startPlayLoop(this.secondaryChannelData, this.state.bpm, 1/16, 0, false, '_ballroom_secondary')
                          this.setState({isPlaying: true})
                          setTimeout(() => {
                            if (this.abcTimingCallbacks) this.abcTimingCallbacks.start()
                          }, 350)
                        }
                        if (this.state.playType === 3) {
                          let root = parse(this.state.urlContent)
                          let rb = []
                          this.playWithInstrument.speed = 4
                          this.playWithInstrument.primary = Instrument.Piano
                          this.playWithInstrument.secondary = Instrument.Cello
                          this.parseElementMulti(root, rb, 0)
                          this.renderABCNotation()
                          this.setState({totalBeatsCount: rb.length, playProgress: 0});
                          this.midiSoundsMultiSecondary.setInstrumentVolume(this.playWithInstrument.primary, 0.35)
                          this.midiSoundsMulti.startPlayLoop(rb, this.state.bpm, 1/16, 0, this.state.record===1, '_nighty_night')
                          this.midiSoundsMultiSecondary.startPlayLoop(this.secondaryChannelData, this.state.bpm, 1/16, 0, false, '_nighty_night_secondary')
                          this.setState({isPlaying: true})
                          setTimeout(() => {
                            if (this.abcTimingCallbacks) this.abcTimingCallbacks.start()
                          }, 350)
                        }
                      }}
                    >
                      <PlayArrowIcon fontSize="large" />
                    </IconButton>
                  </FormControl>
                  <FormControl sx={{mb: 3, mr: 1}}>
                    <IconButton
                      disabled={!this.state.isPlaying}
                      component="span"
                      sx={{ mt: 1 }}
                      variant="outlined"
                      color="secondary"
                      component={RouterLink}
                      to="#"
                      onClick={() => {
                        this.setState({isPlaying: false})
                        this.stopPlaying()
                      }}
                    >
                      <StopIcon fontSize="large"/>
                    </IconButton>
                  </FormControl>
                  {this.state.webRTCAvailable && (
                    <FormControl sx={{mb: 3, mr: 1}}>
                      <IconButton
                        disabled={this.state.isScreenRecording}
                        component="span"
                        sx={{ mt: 1 }}
                        variant="outlined"
                        color="error"
                        component={RouterLink}
                        to="#"
                        onClick={() => {
                          this.recorderRunning = false
                          this.screenRec()
                        }}
                      >
                        <FiberManualRecordIcon fontSize="large"/>
                      </IconButton>
                    </FormControl>
                  )}
                  {this.state.linkToShare && (
                    <FormControl sx={{mb: 3, mr: 1}}>
                      <IconButton
                        component="span"
                        variant="outlined"
                        color="secondary"
                        sx={{ mt: 1.75 }}
                        component={RouterLink}
                        to="#"
                        onClick={() => {
                          navigator.clipboard.writeText(this.state.linkToShare)
                          this.setState({snackOpen: true, snackMessage: 'Link copied to clipboard!'})
                        }}
                      >
                        <ContentCopyIcon fontSize="medium"/>
                      </IconButton>
                    </FormControl>
                  )}
                  {navigator.share && this.state.linkToShare && (
                    <FormControl sx={{mb: 3, mr: 1}}>
                      <IconButton
                        component="span"
                        variant="outlined"
                        color="secondary"
                        sx={{ mt: 1.75 }}
                        component={RouterLink}
                        to="#"
                        onClick={async () => {
                          let shareData = {
                            title: 'HTML2Music',
                            text: 'Ah, it\'s time to relax\nYou know what that means\nA glass of wine, your favorite easy chair\nAnd of course, this melody\nPlaying on your smartphone\nSo, go on, and indulge yourself\nThat\'s right, kick off your shoes\nPut your feet up\nLean back and just enjoy the melodies\nAfter all, music soothes even the savage beast\n\n(inspired by The Offspring)\n \n \n',
                            url: this.state.linkToShare,
                          }
                          await navigator.share(shareData)
                        }}
                      >
                        <ShareIcon fontSize="medium"/>
                      </IconButton>
                    </FormControl>
                  )}
                  <LinearProgress variant="determinate" color="secondary" value={this.state.playProgress} sx={{height: '20px'}} />
                  {/*
                  <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '100%', textAlign: 'center'}}>
                    <Typography noWrap variant="body2" sx={{mt: 1}}>
                      <strong>{this.state.linkToShare}</strong>
                    </Typography>
                  </div>
                  */}
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper square elevation={3} sx={{ p: 2, mb: 3, overflowY: 'auto', maxHeight: '600px'}} style={{overflowX: 'auto'}} id='mojpaper'>
                  <Typography variant="h5">
                    Music score sheet
                  </Typography>
                  <div id='mojdiv'>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper square elevation={3} sx={{ p: 2, mb: 3 }}>
                  <FormControl sx={{mr: 1, mb: 3}}>
                    <InputLabel id="demo-simple-select-label">Key</InputLabel>
                    <Select
                      disabled={this.state.isPlaying}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.keyUsed}
                      label="Key"
                      onChange={(e) => {
                        this.setState({ keyUsed: e.target.value })
                      }}
                    >
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="Cs">C# (Db)</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                      <MenuItem value="Ds">D# (Eb)</MenuItem>
                      <MenuItem value="E">E</MenuItem>
                      <MenuItem value="F">F</MenuItem>
                      <MenuItem value="Fs">F# (Gb)</MenuItem>
                      <MenuItem value="G">G</MenuItem>
                      <MenuItem value="Gs">G# (Ab)</MenuItem>
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="As">A# (Bb)</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{mr: 1, mb: 3}}>
                    <InputLabel id="demo-simple-select-label">Structure depth level</InputLabel>
                    <Select
                      disabled={this.state.isPlaying}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.minimumChildren}
                      label="Structure depth level"
                      onChange={(e) => {
                        this.setState({ minimumChildren: e.target.value })
                      }}
                    >
                      <MenuItem value={0}>Full depth (longest)</MenuItem>
                      <MenuItem value={4}>Half depth (medium)</MenuItem>
                      <MenuItem value={7}>Shallow (short)</MenuItem>
                      <MenuItem value={10}>Highschool jokes shallow (super short)</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{mr: 1, mb: 3}}>
                    <InputLabel id="demo-simple-select-label">Play type</InputLabel>
                    <Select
                      disabled={this.state.isPlaying}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.playType}
                      label="Play type"
                      onChange={(e) => {
                        this.setState({ playType: e.target.value })
                      }}
                    >
                      <MenuItem value={0}><GiGuitarHead sx={{mr: 2}} />&nbsp;&nbsp;Hard Rock</MenuItem>
                      <MenuItem value={1}><GiGrandPiano sx={{mr: 2}} />&nbsp;&nbsp;Piano</MenuItem>
                      <MenuItem value={2}><GiLargeDress sx={{mr: 2}} />&nbsp;&nbsp;Ballroom</MenuItem>
                      <MenuItem value={3}><GiNightSleep sx={{mr: 2}} />&nbsp;&nbsp;Good night sleep</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{mr: 1, mb: 3}}>
                    <InputLabel id="demo-simple-select-label">Press play on tape</InputLabel>
                    <Select
                      sx={{minWidth: '150px'}}
                      disabled={this.state.isPlaying}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.record}
                      label="Press play on tape"
                      onChange={(e) => {
                        this.setState({ record: e.target.value })
                      }}
                    >
                      <MenuItem value={0}>Play once</MenuItem>
                      <MenuItem value={1}>Play once and download (desktop only)</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{mr: 1, mb: 3}}>
                    <InputLabel id="demo-simple-select-label">Run, HTML, run</InputLabel>
                    <Select
                      sx={{minWidth: '150px'}}
                      disabled={this.state.isPlaying}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.bpm}
                      label="Run, HTML, run"
                      onChange={(e) => {
                        this.setState({ bpm: e.target.value })
                      }}
                    >
                      <MenuItem value={40}>Super slow (40 bpm)</MenuItem>
                      <MenuItem value={60}>Slow (60 bpm)</MenuItem>
                      <MenuItem value={80}>Normal (80 bpm)</MenuItem>
                      <MenuItem value={100}>Fast (100 bpm)</MenuItem>
                      <MenuItem value={120}>Faster (120 bpm)</MenuItem>
                      <MenuItem value={140}>Super fast (140 bpm)</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{mr: 1, mb: 3}}>
                    <InputLabel id="demo-simple-select-label">Show music scores</InputLabel>
                    <Select
                      sx={{minWidth: '150px'}}
                      disabled={this.state.isPlaying}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.showScores}
                      label="Show music scores"
                      onChange={(e) => {
                        this.setState({ showScores: e.target.value })
                      }}
                    >
                      <MenuItem value={false}>No (for slower devices)</MenuItem>
                      <MenuItem value={true}>Yes (for computers and fast mobile phones)</MenuItem>
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper square elevation={3} sx={{ p: 2, mb: 3 }}>
                  <FormControl sx={{mr: 1, mb: 3}} fullWidth>
                    <Autocomplete
                      disabled={this.state.isPlaying}
                      multiple
                      id="tags-outlined"
                      options={this.state.allowedTags}
                      getOptionLabel={(option) => option}
                      defaultValue={this.state.allowedTags}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Allowed HTML tags"
                        />
                      )}
                      value={this.state.selectedTags}
                      onChange={(event, newInputValue) => {
                        this.setState({selectedTags: newInputValue})
                      }}
                    />
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper square elevation={3} sx={{ p: 2, mb: 3 }}>
                  <TextField
                    disabled={this.state.isPlaying}
                    id="outlined-basic"
                    label="HTML"
                    variant="outlined"
                    multiline
                    rows={25}
                    sx={{ mb: 3, width: '100%' }}
                    value={this.state.urlContent.substring(0, 65535)}
                    onInput={(e) => {
                      this.setState({ urlContent: e.target.value.substring(0, 65535) })
                      this.setState({ canPlay: true })
                    }}
                  />
                </Paper>
              </Grid>
              {/*
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} sx={{maxHeight: '420px', height: '415px', display: 'none' }}>
                <Paper square elevation={3} sx={{ p: 3, maxHeight: '400px', height: '395px', overflowY: 'auto' }}>
                  <div dangerouslySetInnerHTML={{ __html: this.state.urlContent }}/>
                </Paper>
              </Grid>
              */}
            </Grid>
          </Stack>
        </Container>
      </Page>
    );
  }
}
