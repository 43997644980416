const HalfToneSize = 12

const HalfTones = {
  C: 0,
  Cs: 1,
  D: 2,
  Ds: 3,
  E: 4,
  F: 5,
  Fs: 6,
  G: 7,
  Gs: 8,
  A: 9,
  As: 10,
  B: 11,
}

const ABCNotations = {
  'note_40': 'E,',
  'note_41': 'F,',
  'note_42': '^F,',
  'note_43': 'G,',
  'note_44': '^G,',
  'note_45': 'A,',
  'note_46': '^A,',
  'note_47': 'B,',
  'note_48': 'C',
  'note_49': '^C',
  'note_50': 'D',
  'note_51': '^D',
  'note_52': 'E',
  'note_53': 'F',
  'note_54': '^F',
  'note_55': 'G',
  'note_56': '^G',
  'note_57': 'A',
  'note_58': '^A',
  'note_59': 'B',
  'note_60': 'c',
  'note_61': '^c',
  'note_62': 'd',
  'note_63': '^d',
  'note_64': 'e',
  'note_65': 'f',
  'note_66': '^f',
  'note_67': 'g',
  'note_68': '^g',
  'note_69': 'a',
  'note_70': '^a',
  'note_71': 'b',
  'note_72': 'c\'',
  'note_73': '^c\'',
  'note_74': 'd\'',
  'note_75': '^d\'',
  'note_76': 'e\'',
}

const GuitarStrings = {
  E: 3 * HalfToneSize + HalfTones.E,
  A: 3 * HalfToneSize + HalfTones.A,
  D: 4 * HalfToneSize + HalfTones.D,
  G: 4 * HalfToneSize + HalfTones.G,
  B: 4 * HalfToneSize + HalfTones.B,
  e: 5 * HalfToneSize + HalfTones.E,
}

const GuitarChords = {
  A: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 0,
    GuitarStrings.D + 2,
    GuitarStrings.G + 2,
    GuitarStrings.B + 2,
    GuitarStrings.e + 0,
  ],
  Am: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 0,
    GuitarStrings.D + 2,
    GuitarStrings.G + 2,
    GuitarStrings.B + 1,
    GuitarStrings.e + 0,
  ],
  As: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 1,
    GuitarStrings.D + 3,
    GuitarStrings.G + 3,
    GuitarStrings.B + 3,
    GuitarStrings.e + 1,
  ],
  Asm: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 1,
    GuitarStrings.D + 3,
    GuitarStrings.G + 3,
    GuitarStrings.B + 2,
    GuitarStrings.e + 1,
  ],
  G: [
    GuitarStrings.E + 3,
    GuitarStrings.A + 2,
    GuitarStrings.D + 0,
    GuitarStrings.G + 0,
    GuitarStrings.B + 0,
    GuitarStrings.e + 3,
  ],
  Gm: [
    GuitarStrings.E + 3,
    GuitarStrings.A + 5,
    GuitarStrings.D + 5,
    GuitarStrings.G + 3,
    GuitarStrings.B + 3,
    GuitarStrings.e + 3,
  ],
  Gs: [
    GuitarStrings.E + 4,
    GuitarStrings.A + 6,
    GuitarStrings.D + 6,
    GuitarStrings.G + 5,
    GuitarStrings.B + 4,
    GuitarStrings.e + 4,
  ],
  Gsm: [
    GuitarStrings.E + 4,
    GuitarStrings.A + 6,
    GuitarStrings.D + 6,
    GuitarStrings.G + 4,
    GuitarStrings.B + 4,
    GuitarStrings.e + 4,
  ],
  B: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 2,
    GuitarStrings.D + 4,
    GuitarStrings.G + 4,
    GuitarStrings.B + 4,
    GuitarStrings.e + 2,
  ],
  Bm: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 2,
    GuitarStrings.D + 4,
    GuitarStrings.G + 4,
    GuitarStrings.B + 3,
    GuitarStrings.e + 2,
  ],
  C: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 3,
    GuitarStrings.D + 2,
    GuitarStrings.G + 0,
    GuitarStrings.B + 1,
    GuitarStrings.e + 0,
  ],
  Cm: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 3,
    GuitarStrings.D + 5,
    GuitarStrings.G + 5,
    GuitarStrings.B + 4,
    GuitarStrings.e + 3,
  ],
  Cs: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 4,
    GuitarStrings.D + 3,
    GuitarStrings.G + 1,
    GuitarStrings.B + 2,
    GuitarStrings.e + 1,
  ],
  Csm: [
    //GuitarStrings.E + 0,
    GuitarStrings.A + 4,
    GuitarStrings.D + 6,
    GuitarStrings.G + 6,
    GuitarStrings.B + 5,
    GuitarStrings.e + 4,
  ],
  D: [
    //GuitarStrings.E + 0,
    //GuitarStrings.A + 2,
    GuitarStrings.D + 0,
    GuitarStrings.G + 2,
    GuitarStrings.B + 3,
    GuitarStrings.e + 2,
  ],
  Dm: [
    //GuitarStrings.E + 0,
    //GuitarStrings.A + 2,
    GuitarStrings.D + 0,
    GuitarStrings.G + 2,
    GuitarStrings.B + 3,
    GuitarStrings.e + 1,
  ],
  Ds: [
    //GuitarStrings.E + 0,
    //GuitarStrings.A + 2,
    GuitarStrings.D + 1,
    GuitarStrings.G + 3,
    GuitarStrings.B + 4,
    GuitarStrings.e + 3,
  ],
  Dsm: [
    //GuitarStrings.E + 0,
    //GuitarStrings.A + 2,
    GuitarStrings.D + 1,
    GuitarStrings.G + 3,
    GuitarStrings.B + 4,
    GuitarStrings.e + 2,
  ],
  E: [
    GuitarStrings.E + 0,
    GuitarStrings.A + 2,
    GuitarStrings.D + 2,
    GuitarStrings.G + 1,
    GuitarStrings.B + 0,
    GuitarStrings.e + 0,
  ],
  Em: [
    GuitarStrings.E + 0,
    GuitarStrings.A + 2,
    GuitarStrings.D + 2,
    GuitarStrings.G + 0,
    GuitarStrings.B + 0,
    GuitarStrings.e + 0,
  ],
  F: [
    GuitarStrings.E + 1,
    GuitarStrings.A + 3,
    GuitarStrings.D + 3,
    GuitarStrings.G + 2,
    GuitarStrings.B + 1,
    GuitarStrings.e + 1,
  ],
  Fm: [
    GuitarStrings.E + 1,
    GuitarStrings.A + 3,
    GuitarStrings.D + 3,
    GuitarStrings.G + 1,
    GuitarStrings.B + 1,
    GuitarStrings.e + 1,
  ],
  Fs: [
    GuitarStrings.E + 2,
    GuitarStrings.A + 4,
    GuitarStrings.D + 4,
    GuitarStrings.G + 3,
    GuitarStrings.B + 2,
    GuitarStrings.e + 2,
  ],
  Fsm: [
    GuitarStrings.E + 2,
    GuitarStrings.A + 4,
    GuitarStrings.D + 4,
    GuitarStrings.G + 2,
    GuitarStrings.B + 2,
    GuitarStrings.e + 2,
  ],
}

GuitarChords.Db = GuitarChords.Cs
GuitarChords.Dbm = GuitarChords.Csm
GuitarChords.Eb = GuitarChords.Ds
GuitarChords.Ebm = GuitarChords.Dsm
GuitarChords.Gb = GuitarChords.FCs
GuitarChords.Gbm = GuitarChords.Fsm
GuitarChords.Ab = GuitarChords.Gs
GuitarChords.Abm = GuitarChords.Gsm
GuitarChords.Bb = GuitarChords.As
GuitarChords.Bbm = GuitarChords.Asm

const ChordsInKey = {
  C: [[/* leave empty */], GuitarChords.C, GuitarChords.Dm, GuitarChords.Em, GuitarChords.F, GuitarChords.G, GuitarChords.Am, [/* Bdim */]],
  Cs: [[/* leave empty */], GuitarChords.Cs, GuitarChords.Dsm, GuitarChords.Esm, GuitarChords.Fs, GuitarChords.Gs, GuitarChords.Asm, [/* Bdim */]],
  D: [[/* leave empty */], GuitarChords.D, GuitarChords.Em, GuitarChords.Fsm, GuitarChords.G, GuitarChords.A, GuitarChords.Bm, [/* Bdim */]],
  Ds: [[/* leave empty */], GuitarChords.Ds, GuitarChords.Fm, GuitarChords.Gm, GuitarChords.Gs, GuitarChords.As, GuitarChords.Cm, [/* Bdim */]],
  E: [[/* leave empty */], GuitarChords.E, GuitarChords.Fsm, GuitarChords.Gsm, GuitarChords.A, GuitarChords.B, GuitarChords.Csm, [/* Bdim */]],
  F: [[/* leave empty */], GuitarChords.F, GuitarChords.Gm, GuitarChords.Am, GuitarChords.As, GuitarChords.C, GuitarChords.Dm, [/* Bdim */]],
  Fs: [[/* leave empty */], GuitarChords.Fs, GuitarChords.Gsm, GuitarChords.Asm, GuitarChords.B, GuitarChords.Cs, GuitarChords.Dsm, [/* Bdim */]],
  G: [[/* leave empty */], GuitarChords.G, GuitarChords.Am, GuitarChords.Bm, GuitarChords.C, GuitarChords.D, GuitarChords.Em, [/* Bdim */]],
  Gs: [[/* leave empty */], GuitarChords.Gs, GuitarChords.Asm, GuitarChords.Cm, GuitarChords.Cs, GuitarChords.Ds, GuitarChords.Fm, [/* Bdim */]],
  A: [[/* leave empty */], GuitarChords.A, GuitarChords.Bm, GuitarChords.Csm, GuitarChords.D, GuitarChords.E, GuitarChords.Fsm, [/* Bdim */]],
  As: [[/* leave empty */], GuitarChords.As, GuitarChords.Cm, GuitarChords.Dm, GuitarChords.Ds, GuitarChords.F, GuitarChords.Gm, [/* Bdim */]],
  B: [[/* leave empty */], GuitarChords.B, GuitarChords.Csm, GuitarChords.Dsm, GuitarChords.E, GuitarChords.Fs, GuitarChords.Gsm, [/* Bdim */]],
}

const ChordsInKeyNames = {
  C: ['', 'C', 'Dm', 'Em', 'F', 'G', 'Am', ''],
  Cs: ['', 'C#', 'Dm#', 'Em#', 'F#', 'G#', 'Am#', ''],
  D: ['', 'D', 'Em', 'Fm#', 'G', 'A', 'Bm', ''],
  Ds: ['', 'D#', 'Fm', 'Gm', 'G#', 'A#', 'Cm', ''],
  E: ['', 'E', 'Fm#', 'Gm#', 'A', 'B', 'Cm#', ''],
  F: ['', 'F', 'Gm', 'Am', 'A#', 'C', 'Dm', ''],
  Fs: ['', 'F#', 'Gm#', 'Am#', 'B', 'C#', 'Dm#', ''],
  G: ['', 'G', 'Am', 'Bm', 'C', 'D', 'Em', ''],
  Gs: ['', 'G#', 'Am#', 'Cm', 'C#', 'D#', 'Fm', ''],
  A: ['', 'A', 'Bm', 'Cm#', 'D', 'E', 'Fm#', ''],
  As: ['', 'A#', 'Cm', 'Dm', 'D#', 'F', 'Gm', ''],
  B: ['', 'B', 'Cm#', 'Dm#', 'E', 'F#', 'Gm#', ''],
}

const ChordsProgression = {
  C: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  Cs: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  D: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  Ds: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  E: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  F: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  Fs: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  G: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  Gs: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  A: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  As: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
  B: [[1, 4, 5], [1, 4, 1, 5], [1, 5, 6, 4], [1, 2, 4, 5], [1, 6, 2, 5], [1, 6, 4, 5]],
}

export { GuitarStrings, GuitarChords, ChordsInKey, ChordsProgression, ABCNotations, ChordsInKeyNames }
